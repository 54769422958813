<template>
  <div class="container">
    <h1 class="mb-2">{{ t('partner.properties') }}</h1>
    <hr />
    <div class="flex gap-1 wrap mt-2">
      <template v-if="loading">
        <ImageCardSkeleton />
        <ImageCardSkeleton />
        <ImageCardSkeleton />
      </template>
      <template v-else>
        <template v-if="properties.length > 0">
          <ImageCard v-for="property in properties" :source="property.imageUrl" data-test-id="partnerPropertyCard">
            <div>
              <div class="flex justify-end mb-1">
                <div class="status">{{ t(`common.property.status.${property.status}`) }}</div>
              </div>
              <p class="address title mb-2">{{ property.street }}, {{ property.city }}</p>
            </div>
            <div>
              <hr />
              <p class="rent title mt-1">{{ property.rent ?? '-' }} €/{{ t('common.month') }}</p>
              <p class="plan" v-if="property.pricingPlan">
                {{ t(`property.payment.${property.pricingPlan}.name`) }} {{ t('common.selected') }}
              </p>
              <p class="plan" v-else>{{ t('partner.planNotSelected') }}</p>
            </div>
          </ImageCard>
        </template>
        <div v-else class="empty">
          <div class="flex column align-center gap-1">
            <img :src="images.partnerProperties" class="mb-2" />
            <h2>{{ t('partner.empty.title') }}</h2>
            <p class="title">{{ t('partner.empty.description') }}</p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ImageCard, ImageCardSkeleton } from '@/components'
import { images } from '@/config/assets/images'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { findAllPartnerProperties } from '@/modules/partnerProperty/application'
import type { PartnerProperty } from '@/modules/partnerProperty/domain/partnerProperty'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()

const properties = ref<PartnerProperty[]>([])
const loading = ref(true)

track(AnalyticsEvent.PartnerPropertiesPropertiesList)

onMounted(async () => {
  properties.value = await findAllPartnerProperties()
  loading.value = false
})
</script>
<style lang="sass" scoped>
.empty
  text-align: center
  padding-top: 1rem
  max-width: 48rem

.status
  padding: 0.4rem 0.6rem
  border-radius: 2rem
  background-color: #F4D4FA
  font-size: 0.75rem
  font-weight: 500
</style>
