<template>
  <div class="flex justify-between align-center mt-1">
    <div class="flex align-center gap-1 pointer" @click="emit('toggle')">
      <p v-if="collapsed">{{ t('common.showMore') }}</p>
      <p v-else>{{ t('common.showLess') }}</p>
      <img v-if="collapsed" :src="icons.chevronDown" />
      <img v-else :src="icons.chevronUp" />
    </div>
    <div class="flex align-center gap-05" :class="{ completed: isCompleted, pending: !isCompleted }">
      <img :src="icons.document" />
      <p v-if="isCompleted">{{ totalDocumentCount }} {{ t('application.documentation.documents') }}</p>
      <p v-else>{{ t('application.documentation.pending') }}</p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { icons } from '@/config/assets/icons'
import { isTenantDocumentationCompleted, type Tenant } from '@/modules/tenant/domain/tenant'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{ collapsed: boolean; tenant: Tenant }>()

const emit = defineEmits(['toggle'])

const { t } = useI18n()

const isCompleted = computed(() => isTenantDocumentationCompleted(props.tenant))

const totalDocumentCount = computed(
  () =>
    props.tenant.documents.employmentContract.length +
    props.tenant.documents.id.length +
    props.tenant.documents.payslip.length +
    props.tenant.documents.lastForm100.length +
    props.tenant.documents.lastForm130.length +
    props.tenant.documents.pensionRevaluationCertificate.length
)
</script>
<style lang="sass" scoped>
.completed, .pending
  border-radius: 2rem
  padding: 0.25rem 1rem

.completed
  background-color: #ACDDD1

.pending
  background-color: #FFC8CD
</style>
