<script setup lang="ts"></script>
<template>
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>
<style scoped lang="sass">
.lds-ring,
.lds-ring div
  box-sizing: border-box

.lds-ring
  display: inline-block
  position: relative
  width: 1em
  height: 1em

.lds-ring div
  box-sizing: border-box
  display: block
  position: absolute
  width: 1.75em
  height: 1.75em
  margin-top: -2px
  border: 3px solid currentColor
  border-radius: 50%
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
  border-color: currentColor transparent transparent transparent

.lds-ring div:nth-child(1)
  animation-delay: -0.45s

.lds-ring div:nth-child(2)
  animation-delay: -0.3s

.lds-ring div:nth-child(3)
  animation-delay: -0.15s

@keyframes lds-ring
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
</style>
