export const apiEndpointsV2 = {
  applications: () => ({
    create: () => '/onboarding/applications',
    find: (applicationId: string) => `/onboarding/tenants/me/applications/${applicationId}`,
    list: () => '/onboarding/tenants/me/applications?page=1&pageSize=50',
    submit: (applicationId: string) => `/onboarding/tenants/me/applications/${applicationId}/submit`,
    coTenants: (applicationId: string) => ({
      list: () => `/onboarding/applications/${applicationId}/co-tenants`
    }),
    tenants: (applicationId: string) => ({
      link: (tenantId: string) => `/onboarding/applications/${applicationId}/co-tenants/${tenantId}`,
      unlink: (tenantId: string) => `/onboarding/applications/${applicationId}/co-tenants/${tenantId}`
    })
  }),
  coTenants: () => ({
    find: (coTenantId: string) => `/onboarding/co-tenants/${coTenantId}`,
    documents: (coTenantId: string) => ({
      delete: (documentId: string) => `/onboarding/co-tenant-files/${documentId}`,
      list: () => `/onboarding/co-tenants/${coTenantId}/files`,
      upload: (documentType: string) => `/onboarding/co-tenants/${coTenantId}/files/${documentType}`
    }),
    search: (email: string) => `/onboarding/co-tenants/email-search?value=${encodeURIComponent(email)}`,
    workingStatus: (coTenantId: string) => ({
      update: () => `/onboarding/co-tenants/${coTenantId}/working-status`
    })
  }),
  landlords: () => ({
    documents: () => ({
      upload: (documentType: string) => `/onboarding/landlords/me/files/${documentType}`
    })
  }),
  listings: () => ({
    list: () => '/onboarding/properties'
  }),
  partners: () => ({
    create: () => '/onboarding/partners',
    find: () => '/onboarding/partners/me',
    update: () => '/onboarding/partners/me',
    properties: () => ({
      list: () => '/onboarding/partners/me/properties'
    })
  }),
  properties: () => ({
    find: (propertyId: string) => `/onboarding/properties/${propertyId}`,
    documents: (propertyId: string) => ({
      upload: (documentType: string) => `/onboarding/properties/${propertyId}/files/${documentType}`
    }),
    images: (propertyId: string) => ({
      delete: (imageId: string) => `/onboarding/property-images/${imageId}`,
      list: () => `/onboarding/properties/${propertyId}/images`,
      upload: () => `/onboarding/properties/${propertyId}/images`
    }),
    pricingPlan: (propertyId: string) => ({
      find: () => `/onboarding/properties/${propertyId}/pricing-plan`,
      update: () => `/onboarding/properties/${propertyId}/pricing-plan`
    })
  }),
  tenants: () => ({
    documents: () => ({
      delete: (documentId: string) => `/onboarding/tenants/me/files/${documentId}`,
      list: () => '/onboarding/tenants/me/files',
      upload: (documentType: string) => `/onboarding/tenants/me/files/${documentType}`
    }),
    leads: () => ({
      find: () => '/leads/tenants/me'
    }),
    workingStatus: () => ({
      update: () => '/onboarding/tenants/me/working-status'
    })
  })
}
