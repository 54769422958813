<template>
  <div class="container">
    <h2>{{ t('profile.password.title') }}</h2>
    <hr />
  </div>
  <div class="container narrow">
    <Form @submit="onChangePassword">
      <p class="title">
        {{ t('profile.password.description') }}
      </p>
      <InputField
        v-model="password"
        :error="errors['password']"
        :label="t('profile.password.newPassword')"
        :placeholder="t('profile.password.newPasswordPlaceholder')"
        type="password"
      />
      <InputField
        v-model="passwordConfirmation"
        :error="errors['passwordConfirmation']"
        :label="t('profile.password.confirmNewPassword')"
        :placeholder="t('profile.password.confirmNewPasswordPlaceholder')"
        type="password"
      />
      <Button variant="secondary" :loading="loading" submit>
        {{ t('profile.password.changePassword') }}
      </Button>
      <p v-if="errors['generic']" class="error-message center" data-test-id="genericError">
        {{ t(errors['generic']) }}
      </p>
    </Form>
    <ChangePasswordModal v-if="showConfirmationModal" @close="closeConfirmationModal" />
  </div>
</template>
<script setup lang="ts">
import { Button, ChangePasswordModal, Form, InputField } from '@/components'
import { FormValidationError } from '@/modules/shared/domain/errors/formValidationError'
import { findCurrentUser, updateCurrentPassword } from '@/modules/user/application'
import type { User } from '@/modules/user/domain/user'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const errors = ref<Record<string, string>>({})
const loading = ref(false)
const password = ref<Nullable<string>>(null)
const passwordConfirmation = ref<Nullable<string>>(null)
const user = ref<Nullable<User>>(null)
const showConfirmationModal = ref(false)

onMounted(async () => (user.value = await findCurrentUser()))

async function onChangePassword() {
  loading.value = true
  errors.value = {}
  try {
    await updateCurrentPassword({ password: password.value, passwordConfirmation: passwordConfirmation.value })
    openConfirmationModal()
    password.value = passwordConfirmation.value = null
  } catch (error) {
    if (error instanceof FormValidationError) {
      errors.value = error.errors
    } else if (error instanceof Error) {
      errors.value = { generic: 'genericError.unexpected' }
    }
  }
  loading.value = false
}

function openConfirmationModal() {
  showConfirmationModal.value = true
}

function closeConfirmationModal() {
  showConfirmationModal.value = false
}
</script>
