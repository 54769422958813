<template>
  <div class="flex column gap-1" v-if="model">
    <div v-for="documentType in documentTypes" :id="documentType">
      <p>
        <b>{{ t(`property.documents.type.${documentType}`) }}</b>
        {{ t(`property.documents.description.${documentType}`) }}
      </p>
      <div class="card">
        <FileInput
          v-model="model[documentType]"
          type="file"
          :name="documentType"
          :maxFiles="10"
          :instructions="t('property.documents.uploadInstructions')"
          :trackSelectEvent="uploadEvents[documentType]"
          :trackInvalidFormatEvent="AnalyticsEvent.LandlordPropertyRegisterErrorDocumentationFormat"
          :data-test-id="`${documentType}FileInput`"
          @delete="onDelete(documentType, $event)"
        />
      </div>
    </div>
  </div>
  <FormSkeleton v-else />
</template>
<script lang="ts" setup>
import { FileInput, FormSkeleton } from '@/components'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { deletePropertyDocument } from '@/modules/property/application'
import { requiredPropertyDocumentTypes, type Property } from '@/modules/property/domain/property'
import type { PropertyDocuments } from '@/modules/property/domain/propertyDocuments'
import type { Document } from '@/modules/shared/domain/document/document'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{ id: string; property?: Property }>()
const model = defineModel<PropertyDocuments>()

const documentTypes = computed(() => (props.property ? requiredPropertyDocumentTypes(props.property) : []))

const uploadEvents: Record<keyof PropertyDocuments, AnalyticsEvent> = {
  energyCertificate: AnalyticsEvent.LandlordDocumentationUploadEnergyCertificate,
  habitabilityCertificate: AnalyticsEvent.LandlordDocumentationUploadHabitabilityCertificate,
  propertyExpenses: AnalyticsEvent.LandlordDocumentationUploadPropertyExpenses,
  utilityBill: AnalyticsEvent.LandlordDocumentationUploadBills
}

async function onDelete(documentType: keyof PropertyDocuments, documentToDelete: Document) {
  if (!model.value) return

  model.value[documentType] = model.value[documentType].filter((document) => document !== documentToDelete)
  if (documentToDelete.id) {
    await deletePropertyDocument(props.id, documentToDelete.id)
  }
}
</script>
