import { findCurrentUser } from '@/modules/user/application'
import { PartnerProperties } from '@/views'
import type { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

export const partnerRoutes: RouteRecordRaw = {
  path: '/partner',
  name: 'partner',
  component: PartnerProperties,
  beforeEnter: partnerGuard,
  meta: { partner: true }
}

export async function partnerGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const user = await findCurrentUser()
  return !user.name ? next({ name: 'personalInformation' }) : next()
}
