<template>
  <template v-if="properties?.length === 1">
    <LandlordAccountSuccessModal />
  </template>
  <div class="container">
    <div class="top flex justify-between gap-1">
      <h1>{{ t('properties.myProperties') }}</h1>
      <Button variant="secondary" :loading="creating" @click="onAddProperty" data-test-id="addPropertyButton">
        {{ t('properties.addProperty') }}
      </Button>
    </div>
    <div class="flex gap-1 wrap mt-2">
      <template v-if="loading">
        <ImageCardSkeleton />
        <ImageCardSkeleton />
        <ImageCardSkeleton />
      </template>
      <template v-else>
        <ImageCard v-for="property of properties" :source="property.images.files[0]?.uri">
          <p class="mb-1">{{ property.coreInformation.street ?? t('common.property.newProperty') }}</p>
          <div class="flex justify-between align-center" v-if="property.listed">
            <PropertyStatusBlob :label="t('common.property.listed')" />
            <RouterLink class="icon" :to="{ name: 'propertyDetails', params: { id: property.id } }">
              <IconButton :icon="icons.arrowRight" border small data-test-id="propertyDetails" />
            </RouterLink>
          </div>
          <Button
            v-else
            @click="onContinue(property.id)"
            variant="secondary"
            class="button"
            data-test-id="propertyLink"
          >
            {{ t('properties.continueRegistration') }}
          </Button>
        </ImageCard>
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  IconButton,
  Button,
  ImageCard,
  ImageCardSkeleton,
  LandlordAccountSuccessModal,
  PropertyStatusBlob
} from '@/components'
import { icons } from '@/config/assets/icons'
import { addProperty, findAllProperties } from '@/modules/property/application'
import { type Property } from '@/modules/property/domain/property'
import { findCurrentUser } from '@/modules/user/application'
import type { User } from '@/modules/user/domain/user'
import { router } from '@/router'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'

const { t } = useI18n()

const properties = ref<Property[]>([])
const loading = ref(true)
const creating = ref(false)
const user = ref<Nullable<User>>(null)

onMounted(async () => {
  properties.value = await findAllProperties()
  user.value = await findCurrentUser()
  loading.value = false
})

function onContinue(id: string) {
  router.push({ name: 'resumeOnboarding', params: { id } })
}

async function onAddProperty() {
  creating.value = true
  try {
    const { id } = await addProperty()
    router.push({ name: 'coreInformation', params: { id } })
  } catch {}
  creating.value = false
}
</script>
<style scoped lang="sass">
@media only screen and (max-width: 768px)
  .top
    flex-direction: column

p
  font-size: 1rem
  font-weight: bold
</style>
