import { apiEndpointsV1 as v1 } from '@/config/api/endpoints.v1'
import { apiEndpointsV2 as v2 } from '@/config/api/endpoints.v2'
import type { AuthService } from '@/modules/auth/domain/authService'
import type { ApiService } from '@/modules/shared/domain/api/apiService'
import type { Document } from '@/modules/shared/domain/document/document'
import type { Uuid } from '@/modules/shared/domain/uuid/uuid'
import { asyncForEach, asyncMap } from '@/utils/array'
import type { TenantDocuments } from '../domain/tenantDocuments'
import type { TenantRepository } from '../domain/tenantRepository'
import type { UpdateTenantForm } from '../domain/updateTenantForm'
import {
  fromCoTenantDto,
  fromDocumentDto,
  fromTenantDto,
  type CoTenantDto,
  type TenantDocumentDto,
  type TenantDto
} from './apiDto'

export function apiTenantRepositoryBuilder(
  apiService: ApiService,
  apiV2Service: ApiService,
  authService: AuthService
): TenantRepository {
  async function updateDocuments(documents: TenantDocuments, coTenantId?: string): Promise<TenantDocuments> {
    const id = await uploadDocuments('personal-id', documents.id, coTenantId)
    const payslip = await uploadDocuments('payslip', documents.payslip, coTenantId)
    const lastForm100 = await uploadDocuments('form-100', documents.lastForm100, coTenantId)
    const lastForm130 = await uploadDocuments('form-130', documents.lastForm130, coTenantId)
    const employmentContract = await uploadDocuments('employment-contract', documents.employmentContract, coTenantId)
    const pensionRevaluationCertificate = await uploadDocuments(
      'pension-revaluation-certificate',
      documents.pensionRevaluationCertificate,
      coTenantId
    )

    return {
      employmentContract,
      id,
      lastForm100,
      lastForm130,
      payslip,
      pensionRevaluationCertificate
    }
  }

  async function uploadDocuments(type: string, documents: Document[], coTenantId?: string): Promise<Document[]> {
    const filesToUpload = documents.filter((file) => file.source).map((file) => file.source as File)

    if (filesToUpload.length === 0) {
      return documents
    }

    await asyncForEach(filesToUpload, async (file) => {
      const uploadEndpoint = coTenantId
        ? v2.coTenants().documents(coTenantId).upload(type)
        : v2.tenants().documents().upload(type)
      await apiV2Service.upload(uploadEndpoint, file)
    })

    const getEndpoint = coTenantId ? v2.coTenants().documents(coTenantId).list() : v2.tenants().documents().list()
    const dtos = await apiV2Service.getList<TenantDocumentDto>(getEndpoint)
    return dtos.map(fromDocumentDto)
  }

  return {
    async create(tenantId: Uuid, { name, surname, email, phone }: UpdateTenantForm) {
      await apiService.post(v1.tenants().cotenants(tenantId).create(), { name, surname, email, phone })
    },
    async deleteDocument(tenantId: Uuid, documentId: string) {
      const userId = await authService.getUserId()
      if (userId === tenantId) {
        return apiV2Service.delete(v2.tenants().documents().delete(documentId))
      } else {
        return apiV2Service.delete(v2.coTenants().documents(tenantId).delete(documentId))
      }
    },
    async getByApplication(applicationId: Uuid) {
      const userId = await authService.getUserId()
      const mainTenantDto = await apiService.get<TenantDto>(v1.tenants().find(userId))
      const mainTenantDocumentDtos = await apiV2Service.getList<TenantDocumentDto>(v2.tenants().documents().list())

      const coTenantIds = await apiV2Service.getList<{ coTenantId: string }>(
        v2.applications().coTenants(applicationId).list()
      )

      const coTenants = await asyncMap(coTenantIds, async ({ coTenantId }) => {
        const coTenantDto = await apiV2Service.get<CoTenantDto>(v2.coTenants().find(coTenantId))
        return fromCoTenantDto(coTenantDto)
      })

      return [fromTenantDto(mainTenantDto, mainTenantDocumentDtos), ...coTenants]
    },
    async getByEmail(email: string) {
      try {
        const dto = await apiV2Service.get<CoTenantDto>(v2.coTenants().search(email))
        return fromCoTenantDto(dto)
      } catch {
        return null
      }
    },
    async getLead() {
      return apiV2Service.get(v2.tenants().leads().find())
    },
    async update(tenantId: Uuid, form: UpdateTenantForm) {
      const userId = await authService.getUserId()
      const { employmentStatus, name, phone, surname } = form

      if (userId === tenantId) {
        await apiService.patch(v1.tenants().update(userId), { name, phone, surname })
        if (employmentStatus !== null) {
          await apiV2Service.put(v2.tenants().workingStatus().update(), { workingStatus: employmentStatus })
        }
      } else if (employmentStatus !== null) {
        await apiV2Service.put(v2.coTenants().workingStatus(tenantId).update(), { workingStatus: employmentStatus })
      }
    },
    async updateDocuments(tenantId: Uuid, documents: TenantDocuments) {
      const userId = await authService.getUserId()
      if (userId === tenantId) {
        return updateDocuments(documents)
      } else {
        const coTenantDocumentDtos = await apiV2Service.getList<{ id: string }>(
          v2.coTenants().documents(tenantId).list()
        )
        await asyncForEach(coTenantDocumentDtos, async ({ id }) => {
          try {
            apiV2Service.delete(v2.coTenants().documents(tenantId).delete(id))
          } catch {}
        })
        return updateDocuments(documents, tenantId)
      }
    }
  }
}
