import { apiEndpointsV1 as v1 } from '@/config/api/endpoints.v1'
import { apiEndpointsV2 as v2 } from '@/config/api/endpoints.v2'
import { i18n } from '@/i18n'
import type { ApiService } from '@/modules/shared/domain/api/apiService'
import { generateUuid } from '@/modules/shared/domain/uuid/uuid'
import type { UpdateUserForm } from '../domain/updateUserForm'
import { UserType, type User } from '../domain/user'
import type { UserRepository } from '../domain/userRepository'
import { fromPartnerDto, fromUserDto, type PartnerApiDto, type UserApiDto } from './apiDto'

export function apiUserRepositoryBuilder(apiService: ApiService, apiV2Service: ApiService): UserRepository {
  let cache: Nullable<User> = null
  let cacheId: Nullable<string> = null

  return {
    async create(type: UserType, email: string, password?: string) {
      if (type === UserType.Landlord) {
        await apiService.post(v1.landlords().create(), { email, password })
      } else if (type === UserType.Partner) {
        const id = generateUuid()
        await apiV2Service.post(v2.partners().create(), { id, name: null, contact: null })
      } else {
        await apiService.post(v1.tenants().create(), { email, password })
      }
    },
    async delete({ id, type }: User) {
      if (type === UserType.Landlord) {
        await apiService.delete(v1.landlords().delete(id))
      } else if (type === UserType.Partner) {
        throw new Error('Not implemented')
      } else {
        await apiService.delete(v1.tenants().delete(id))
      }
    },
    async get(id: string) {
      if (id === cacheId && cache !== null) {
        return cache
      }

      try {
        const dto = await apiService.get<UserApiDto>(v1.landlords().find(id))
        cache = fromUserDto(dto, UserType.Landlord)
      } catch {
        try {
          const dto = await apiService.get<UserApiDto>(v1.tenants().find(id))
          cache = fromUserDto(dto, UserType.Tenant)
        } catch {
          const dto = await apiV2Service.get<PartnerApiDto>(v2.partners().find())
          cache = fromPartnerDto(dto)
        }
      }

      cacheId = id
      return cache
    },
    async resetPassword(token: string, password: string, type: UserType) {
      if (type === UserType.Landlord) {
        await apiService.post(`/landlords/reset-password`, { token, password })
      } else {
        await apiService.post(`/tenants/reset-password`, { token, password })
      }
    },
    async sendPasswordRecoveryEmail(email: string) {
      const lang = i18n.global.locale.value
      try {
        await apiService.post(`/landlords/forgot-password`, { email, lang })
      } catch {
        await apiService.post(`/tenants/forgot-password`, { email, lang })
      }
    },
    async update(id: string, form: UpdateUserForm) {
      if (id === cacheId && cache !== null) {
        cache = { ...cache, ...form }
      }

      const { company, name, phone, type, surname } = form
      const lang = i18n.global.locale.value

      if (type === UserType.Landlord) {
        await apiService.patch(v1.landlords().update(id, lang), { name, phone, surname })
      } else if (type === UserType.Partner) {
        await apiV2Service.patch(v2.partners().update(), {
          name: company,
          contact: { name, phone, surname }
        })
      } else {
        await apiService.patch(v1.tenants().update(id), { name, phone, surname })
      }
    }
  }
}
