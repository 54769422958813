import { zodGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/zod/zodGenericSchemaValidator'
import type { SignInForm } from '@/modules/user/domain/signInForm'
import { z } from 'zod'

export const signInFormSchema = z.object({
  email: z.string().regex(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/, 'validationError.formatEmail'),
  password: z.string().regex(/^.{6,20}$/, 'validationError.formatPassword')
})

export function zodSignInFormValidatorBuilder() {
  return zodGenericSchemaValidatorBuilder<SignInForm>(signInFormSchema)
}
