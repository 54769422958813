<template>
  <footer class="footer">
    <div class="button-container">
      <Loader v-if="loading" />
      <template v-else>
        <p class="exit-text" @click="emit('exit')" data-test-id="saveAndExitButton">
          {{ t('application.saveAndExit') }}
        </p>
        <Button variant="secondary" :disabled="!canSend" @click="emit('send')">
          {{ t('application.send') }}
        </Button>
      </template>
    </div>
  </footer>
</template>
<script setup lang="ts">
import { Button, Loader } from '@/components'
import { useI18n } from 'vue-i18n'

withDefaults(defineProps<{ loading: boolean; canSend: boolean }>(), { loading: false, canSend: false })

const emit = defineEmits(['send', 'exit'])

const { t } = useI18n()
</script>
<style lang="sass" scoped>
.footer
  border-top: 1px solid $grey200
  padding: $paddingS 0
  width: 100%
  background-color: $brandLight400
  position: relative
  z-index: 7

.button-container
  display: flex
  gap: 2rem
  align-items: center
  justify-content: center
  margin: 0 $paddingS
  padding: 0 $paddingXL
  height: 3.25rem

  @media only screen and (max-width: 768px)
    flex-direction: column-reverse
    height: auto
    gap: 1rem

.exit-text
  font-size: 16px
  color: $grey500
  display: flex
  align-items: center
  cursor: pointer
</style>
