import { z, ZodObject, ZodEffects } from 'zod'

import { FormValidationError } from '@/modules/shared/domain/errors/formValidationError'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function zodGenericSchemaValidatorBuilder<T>(schema: ZodEffects<ZodObject<any>> | ZodObject<any>): Validator<T> {
  return (primitive: Primitive<T>): T => {
    try {
      return schema.parse(primitive) as T
    } catch (e) {
      const error = e as z.ZodError
      const errors: Record<string, string> = {}
      error.issues.forEach((issue) => {
        const [field] = issue.path as string[]
        const message = issue.message.startsWith('validationError') ? issue.message : 'validationError.required'
        errors[field] = message
      })
      throw new FormValidationError(errors)
    }
  }
}
