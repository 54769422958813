<template>
  <RouterLink class="link" :to="{ name: 'partner' }" :class="{ active: route.name === 'partner' }">
    <img :src="icons.properties" />
    <span>{{ t('partner.properties') }}</span>
  </RouterLink>
</template>
<script lang="ts" setup>
import { icons } from '@/config/assets/icons'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const route = useRoute()
</script>
