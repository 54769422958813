import type { PartnerProperty, PartnerPropertyStatus } from '@/modules/partnerProperty/domain/partnerProperty'
import { PricingPlan } from '@/modules/property/domain/propertyPaymentInformation'

export interface PartnerPropertyDto {
  id: string
  address: {
    street: string
    city: string
  }
  rent: {
    amount: number
  }
  status: PartnerPropertyStatus
}

export interface PartnerPropertyImageDto {
  id: string
  name: string
  mimeType: 'image/jpg' | 'image/jpeg' | 'image/png'
}

export interface PricingPlanDto {
  pricingPlan: { id: string }
}

export function fromPricingPlanDto(dto: PricingPlanDto) {
  if (dto.pricingPlan.id === '1b61bc70-8be3-4a99-a957-5d1fdc7a6d42') {
    return PricingPlan.Secured
  } else if (dto.pricingPlan.id === '35ffbaa3-c37c-4d21-947a-9cc52d205b7f') {
    return PricingPlan.PeaceOfMind
  } else if (dto.pricingPlan.id === '0fec4ed1-f30f-44e9-9e01-1eb3fa0491b4') {
    return PricingPlan.Basic
  } else {
    return null
  }
}

function fromImageDtos(propertyId: string, dtos: PartnerPropertyImageDto[]): Nullable<string> {
  if (dtos.length === 0) return null

  const extensions = {
    'image/jpg': 'jpg',
    'image/jpeg': 'jpeg',
    'image/png': 'png'
  }
  const firstImage = dtos[0]
  return `${import.meta.env.VITE_FILE_STORAGE_BASE_URL}/properties/${propertyId}/images/${firstImage.id}.${extensions[firstImage.mimeType]}`
}

export function fromPartnerPropertyDto(
  dto: PartnerPropertyDto,
  imageDtos: PartnerPropertyImageDto[],
  pricingPlanDto: Nullable<PricingPlanDto>
): PartnerProperty {
  return {
    pricingPlan: pricingPlanDto ? fromPricingPlanDto(pricingPlanDto) : null,
    status: dto.status,
    city: dto.address.city,
    imageUrl: fromImageDtos(dto.id, imageDtos),
    rent: dto.rent.amount,
    id: dto.id,
    street: dto.address.street
  }
}
