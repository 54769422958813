import { apiService, apiV2Service } from '@/modules/shared/infrastructure/api'
import { booleanStorageBuilder } from '@/modules/shared/infrastructure/storage/booleanStorage'
import { dateStorageBuilder } from '@/modules/shared/infrastructure/storage/dateStorage'
import { apiLandlordRepositoryBuilder } from './apiLandlordRepository'
import { yupLandlordDocumentsValidatorBuilder } from './yupLandlordDocumentsValidator'

export const landlordRepository = apiLandlordRepositoryBuilder(
  apiService,
  apiV2Service,
  dateStorageBuilder('landlordTermsAndConditionsDate'),
  booleanStorageBuilder('landlordBasicPlanVisibility')
)

export const landlordDocumentsValidator = yupLandlordDocumentsValidatorBuilder()
