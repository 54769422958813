<template>
  <div class="basic-layout">
    <nav class="navbar">
      <RouterLink :to="{ name: 'home' }">
        <img :src="images.vivaraLogoDark" />
      </RouterLink>
      <div class="flex gap-1 align-center">
        <LanguageSelector />
        <ProfileDropdown v-if="user" :user="user" />
      </div>
    </nav>
    <div class="content"><slot /></div>
    <div class="footer">
      <div id="basicLayoutFooter"></div>
      <p class="light">{{ t('footer.copyright') }}</p>
      <p class="light">{{ t('footer.registry') }}</p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { LanguageSelector, ProfileDropdown } from '@/components'
import { images } from '@/config/assets/images'
import type { User } from '@/modules/user/domain/user'
import { useI18n } from 'vue-i18n'

defineProps<{ user: Nullable<User> }>()

const { t } = useI18n()
</script>
<style lang="sass" scoped>
@use "@/assets/styles/responsive.sass" as *

$navbar-height: 4rem
$footer-height: 3.5rem
$footer-height-mobile: 7rem

.navbar
  height: $navbar-height
  display: flex
  justify-content: space-between
  align-items: center
  border-bottom: 1px solid #f0f0f0
  padding: 0rem 1rem
  box-sizing: border-box
  background-color: white

.footer
  height: $footer-height-mobile
  display: flex
  flex-direction: column
  align-items: center
  border-top: 1px solid #f0f0f0
  padding: 0.5rem 1rem
  box-sizing: border-box

  p
    font-size: 0.75rem

  @include md
    height: $footer-height
    flex-direction: row
    justify-content: space-between

    p
      font-size: 0.875rem

.content
  padding-bottom: 2rem
  min-height: calc(100vh - #{$navbar-height} - #{$footer-height-mobile} - 2rem)

  @include md
    min-height: calc(100vh - #{$navbar-height} - #{$footer-height} - 2rem)

#basicLayoutFooter
  position: fixed
  bottom: 0
  left: 0
  width: 100%
  z-index: 10
</style>
