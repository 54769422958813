<template>
  <OnboardingCompletedModal v-if="showOnboardingCompletedModal" />
  <div class="container fill">
    <div class="row">
      <div class="col-4 col-md-8 col-lg-3">
        <PropertyStepper :property="property" :landlord="landlord" />
      </div>
      <div class="col-4 col-md-8 col-lg-6">
        <CoreInformationForm v-model="coreInformation" />
      </div>
    </div>
  </div>
  <Teleport to="#basicLayoutFooter" v-if="landlord && property && coreInformation">
    <PropertyStepper mobile :property="property" :landlord="landlord" />
    <OnboardingFooter
      v-if="!showOnboardingCompletedModal"
      :loading="loading"
      :landlord="landlord"
      :property="{ ...property, coreInformation }"
      @continue="onContinue"
      @finish="onFinish"
      @exit="onExit"
    />
  </Teleport>
</template>
<script lang="ts" setup>
import { CoreInformationForm, OnboardingCompletedModal, OnboardingFooter, PropertyStepper } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { findCurrentLandlord } from '@/modules/landlord/application'
import type { Landlord } from '@/modules/landlord/domain/landlord'
import {
  findPropertyById,
  finishPropertyOnboarding,
  updatePropertyCoreInformation
} from '@/modules/property/application'
import type { Property } from '@/modules/property/domain/property'
import type { PropertyCoreInformation } from '@/modules/property/domain/propertyCoreInformation'
import { router } from '@/router'
import { onMounted, ref } from 'vue'

const { track } = useAnalytics()

const id = router.currentRoute.value.params.id as string
const landlord = ref<Landlord | undefined>()
const property = ref<Property | undefined>()
const coreInformation = ref<PropertyCoreInformation | undefined>()
const loading = ref(false)
const showOnboardingCompletedModal = ref(false)

track(AnalyticsEvent.LandlordPropertyRegisterStartPropertyData)

onMounted(async () => {
  landlord.value = await findCurrentLandlord()
  property.value = await findPropertyById(id)
  coreInformation.value = { ...property.value.coreInformation }
})

async function save() {
  if (!coreInformation.value) return

  loading.value = true
  try {
    await updatePropertyCoreInformation(id, coreInformation.value)
    track(AnalyticsEvent.LandlordPropertyRegisterSubmitPropertyData)
  } catch {}
  loading.value = false
}

async function onExit() {
  await save()
  router.push({ name: 'properties' })
}

async function onContinue() {
  await save()
  router.push({ name: 'basicInformation' })
}

async function onFinish() {
  await save()
  await finishPropertyOnboarding(id)
  track(AnalyticsEvent.LandlordOnboardingCompleted)
  showOnboardingCompletedModal.value = true
}
</script>
