import { apiEndpointsV2 as v2 } from '@/config/api/endpoints.v2'
import type { PartnerPropertyRepository } from '@/modules/partnerProperty/domain/partnerPropertyRepository'
import type { ApiService } from '@/modules/shared/domain/api/apiService'
import { asyncMap } from '@/utils/array'
import {
  fromPartnerPropertyDto,
  type PartnerPropertyDto,
  type PartnerPropertyImageDto,
  type PricingPlanDto
} from './apiDto'

export function apiPartnerPropertyRepositoryBuilder(apiV2Service: ApiService): PartnerPropertyRepository {
  return {
    async getAll() {
      const dtos = await apiV2Service.getList<PartnerPropertyDto>(v2.partners().properties().list())

      return asyncMap(dtos, async (dto) => {
        let imagesDtos: PartnerPropertyImageDto[] = []
        try {
          imagesDtos = await apiV2Service.getList<PartnerPropertyImageDto>(v2.properties().images(dto.id).list())
        } catch {}

        let pricingPlanDto: Nullable<PricingPlanDto> = null
        try {
          pricingPlanDto = await apiV2Service.get<PricingPlanDto>(v2.properties().pricingPlan(dto.id).find())
        } catch {}

        return fromPartnerPropertyDto(dto, imagesDtos, pricingPlanDto)
      })
    }
  }
}
