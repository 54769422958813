<template>
  <div class="flex column gap-1">
    <Button variant="secondary" :icon="images.googleLogo" @click="onSignUpWithGoogle">
      {{ t('auth.signInWithGoogle') }}
    </Button>
    <p v-if="errorMessage" class="error-message center">{{ t(errorMessage) }}</p>
  </div>
</template>
<script lang="ts" setup>
import { Button } from '@/components'
import { images } from '@/config/assets/images'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { createApplication } from '@/modules/application/application'
import { findTenantLead } from '@/modules/tenant/application'
import { signUpWithGoogle } from '@/modules/user/application'
import { isLandlord, isTenant, UserType } from '@/modules/user/domain/user'
import { router } from '@/router'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  userType: UserType
  navigateOnSuccess: string
}>()

const { t } = useI18n()
const { track } = useAnalytics()

const errorMessage = ref('')

async function linkTenantApplication() {
  try {
    const { propertyId } = await findTenantLead()
    if (propertyId) {
      await createApplication(propertyId)
    }
  } catch {}
}

async function onSignUpWithGoogle() {
  errorMessage.value = ''

  try {
    await signUpWithGoogle(props.userType)
    if (isLandlord(props.userType)) {
      track(AnalyticsEvent.LandlordSignupInitiateRegister)
      track(AnalyticsEvent.LandlordSignupRegisterWithGoogle)
    } else if (isTenant(props.userType)) {
      track(AnalyticsEvent.TenantSignupInitiateRegister)
      track(AnalyticsEvent.TenantSignupRegisterWithGoogle)
      await linkTenantApplication()
    }
    router.push({ name: props.navigateOnSuccess })
  } catch (error) {
    errorMessage.value = 'genericError.unexpected'
  }
}
</script>
