<template>
  <p class="light mb-1">{{ t('tenant.employmentStatusDescription') }}</p>
  <template v-if="model.employmentStatus">
    <p class="title">
      {{ t(`tenant.requiredDocumentation.${model.employmentStatus}`) }}
    </p>
    <template v-for="documentType in employmentStatusDocumentTypes[model.employmentStatus]">
      <p class="mb-05 mt-1">
        <b>{{ t(`tenant.documents.${documentType}`) }}</b>
      </p>
      <FileInput
        class="mb-2"
        v-model="model.documents[documentType]"
        type="file"
        :name="documentType + model.id"
        :maxFiles="10"
        :instructions="t('tenant.documents.uploadInstructions')"
        :data-test-id="`${documentType}FileInput`"
        @delete="onDeleteDocument(documentType, $event)"
      />
    </template>
  </template>
  <template v-else>
    <p class="title">
      {{ t('tenant.documentation') }}
    </p>
    <div class="placeholder">
      <img :src="icons.addNotes" />
      <p>{{ t('tenant.employmentStatusNotSelected') }}</p>
    </div>
  </template>
</template>
<script lang="ts" setup>
import { FileInput } from '@/components'
import { icons } from '@/config/assets/icons'
import type { Document } from '@/modules/shared/domain/document/document'
import type { Tenant } from '@/modules/tenant/domain/tenant'
import { employmentStatusDocumentTypes, TenantDocumentType } from '@/modules/tenant/domain/tenantDocuments'
import { useI18n } from 'vue-i18n'

const model = defineModel<Tenant>({ required: true })

const emit = defineEmits<{
  (e: 'delete', document: Document): void
}>()

const { t } = useI18n()

async function onDeleteDocument(documentType: TenantDocumentType, documentToDelete: Document) {
  model.value.documents[documentType] = model.value.documents[documentType].filter(
    (document) => document !== documentToDelete
  )
  emit('delete', documentToDelete)
}
</script>
<style lang="sass" scoped>
.placeholder
  text-align: center
  border-radius: 1rem
  padding: 1.5em 2rem
  background-color: #E6E6E6

  img
    opacity: 0.4
    margin-bottom: 0.5rem

  p
    color: #9F9F9F
</style>
