<template>
  <Modal @close="emit('close')" mandatory>
    <div class="flex align-center justify-center column gap-1 p-1 pt-3 primary">
      <img :src="images.congratulations" />
      <h2>{{ t('profile.password.changePassword') }}</h2>
      <p class="pl-2 pr-2">
        {{ t('profile.password.changePasswordConfirmation') }}
      </p>
    </div>
    <div class="flex align-center justify-center column gap-1 p-1 pb-3">
      <Button @click="emit('close')">
        {{ t('common.continue') }}
      </Button>
    </div>
  </Modal>
</template>
<script setup lang="ts">
import { Button, Modal } from '@/components'
import { images } from '@/config/assets/images'
import { useI18n } from 'vue-i18n'

const emit = defineEmits(['close'])

const { t } = useI18n()
</script>
