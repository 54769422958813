import addNotes from '@/assets/icons/add_notes.svg'
import addPhoto from '@/assets/icons/add_photo.svg'
import applications from '@/assets/icons/applications.svg'
import arrowBack from '@/assets/icons/arrow_back.svg'
import arrowOutward from '@/assets/icons/arrow_outward.svg'
import arrowRight from '@/assets/icons/arrow_right.svg'
import check from '@/assets/icons/check.svg'
import checkCircleFilled from '@/assets/icons/check_circle_filled.svg'
import checkCircleFilledGreen from '@/assets/icons/check_circle_filled_green.svg'
import checkCircleOutline from '@/assets/icons/check_circle_outline.svg'
import checkGreen from '@/assets/icons/check_green.svg'
import chevronDown from '@/assets/icons/chevron_down.svg'
import chevronUp from '@/assets/icons/chevron_up.svg'
import close from '@/assets/icons/close.svg'
import deleteIcon from '@/assets/icons/delete.svg'
import document from '@/assets/icons/document.svg'
import documentLight from '@/assets/icons/document_light.svg'
import infoCircle from '@/assets/icons/info_circle.svg'
import logout from '@/assets/icons/logout.svg'
import menu from '@/assets/icons/menu.svg'
import properties from '@/assets/icons/properties.svg'
import propertyTypeAttachedHouse from '@/assets/icons/property_type_attached_house.svg'
import propertyTypeDetachedHouse from '@/assets/icons/property_type_detached_house.svg'
import propertyTypeDuplex from '@/assets/icons/property_type_duplex.svg'
import propertyTypeFlat from '@/assets/icons/property_type_flat.svg'
import propertyTypePenthouse from '@/assets/icons/property_type_penthouse.svg'
import travelExplore from '@/assets/icons/travel_explore.svg'

export const icons = {
  addNotes,
  addPhoto,
  applications,
  arrowBack,
  arrowOutward,
  arrowRight,
  check,
  checkCircleFilled,
  checkCircleFilledGreen,
  checkCircleOutline,
  checkGreen,
  chevronDown,
  chevronUp,
  close,
  delete: deleteIcon,
  document,
  documentLight,
  infoCircle,
  logout,
  menu,
  properties,
  propertyTypeAttachedHouse,
  propertyTypeDetachedHouse,
  propertyTypeDuplex,
  propertyTypeFlat,
  propertyTypePenthouse,
  travelExplore
}
