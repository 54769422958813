import { UserType, type User } from '@/modules/user/domain/user'

export interface UserApiDto {
  email: string
  id: string
  name: string
  phone: Nullable<string>
  surname: Nullable<string>
}

export function fromUserDto(dto: UserApiDto, type: UserType): User {
  return {
    company: null,
    email: dto.email,
    id: dto.id,
    name: dto.name,
    phone: dto.phone ?? null,
    surname: dto.surname ?? null,
    type
  }
}

export interface PartnerApiDto {
  id: string
  email: string
  name: Nullable<string>
  contact: Nullable<{
    name: string
    surname: string
    phone: Nullable<string>
  }>
}

export function fromPartnerDto(dto: PartnerApiDto): User {
  return {
    email: dto.email,
    id: dto.id,
    name: dto.contact?.name ?? null,
    phone: dto.contact?.phone?.replace(/ /g, '') ?? null,
    surname: dto.contact?.surname ?? null,
    company: dto.name,
    type: UserType.Partner
  }
}
