<template>
  <div class="container row">
    <div class="col-3">
      <SignupStepper v-if="!isPartner(userType)" :step="2" :showPropertyStep="isLandlord(userType)" />
    </div>
    <div class="col-lg-6 col-md-8 col-4">
      <Form @submit="onSignUp">
        <div>
          <h2>{{ t('signup.welcome') }} <br /></h2>
          <Title :path="`signup.account.${userType}.title`" />
        </div>
        <p>{{ t(`signup.account.${userType}.subtitle`) }}</p>
        <template v-if="!isPartner(userType)">
          <SocialSignUp :userType="userType" navigateOnSuccess="personalInformation" />
          <OrSeparator />
        </template>
        <InputField
          name="email"
          v-model="email"
          :error="errors['email']"
          :label="t('forms.email')"
          :placeholder="t('forms.emailPlaceholder')"
          type="email"
          data-test-id="emailInput"
        />
        <InputField
          v-model="password"
          :error="errors['password']"
          :label="t('forms.password')"
          :placeholder="t('forms.passwordPlaceholder')"
          type="password"
          data-test-id="passwordInput"
        />
        <p class="light">{{ t('validationError.formatPassword') }}</p>
        <p v-if="errors['auth']" class="error-message center" data-test-id="authenticationError">
          {{ t(errors['auth']) }}
        </p>
        <Button :loading="loading" submit data-test-id="signUpButton">
          {{ t(`signup.account.${userType}.signUpWithEmail`) }}
        </Button>
        <p class="mb-1">
          {{ t('signup.account.signInPrompt') }}
          <router-link :to="{ name: 'signIn' }">{{ t('common.signIn') }}</router-link>
        </p>
        <div data-test-id="termsAndConditions">
          <div class="flex gap-05">
            <Checkbox v-model="toc" :trackSelectEvent="selectTermsAndConditionsEvents[userType]" />
            <p>
              <span>{{ t('signup.account.terms') }}&nbsp;</span>
              <TermsAndPolicyLink :userType="userType" />
              <span>&nbsp;{{ t('signup.account.termsEnd') }}</span>
            </p>
          </div>
          <div v-if="errors['termsAndConditions']" class="error-message" data-test-id="termsAndConditionsError">
            {{ t(errors['termsAndConditions']) }}
          </div>
        </div>
        <div class="flex gap-05">
          <Checkbox v-model="newsletter" :trackSelectEvent="signupSelectCommercialCommunicationsEvents[userType]" />
          <p>{{ t('signup.account.commercial') }}</p>
        </div>
      </Form>
    </div>
  </div>
  <div class="fixed-bottom" v-if="!isPartner(userType)">
    <SignupStepper :step="2" mobile />
  </div>
</template>
<script lang="ts" setup>
import {
  Button,
  Checkbox,
  Form,
  InputField,
  OrSeparator,
  SignupStepper,
  SocialSignUp,
  TermsAndPolicyLink,
  Title
} from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { createApplication } from '@/modules/application/application'
import { FormValidationError } from '@/modules/shared/domain/errors/formValidationError'
import { findTenantLead } from '@/modules/tenant/application'
import { signUp } from '@/modules/user/application'
import { UserType, isLandlord, isPartner, isTenant } from '@/modules/user/domain/user'
import { router } from '@/router'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()

const userType = router.currentRoute.value.params.type as UserType
const email = ref<string>('')
const password = ref<string>('')
const toc = ref(false)
const newsletter = ref(false)
const loading = ref(false)
const errors = ref<Record<string, string>>({})

const selectTermsAndConditionsEvents: Record<UserType, AnalyticsEvent> = {
  landlord: AnalyticsEvent.LandlordSignupSelectTermsAndConditions,
  tenant: AnalyticsEvent.TenantSignupSelectTermsAndConditions,
  partner: AnalyticsEvent.PartnerSignupSelectTermsAndConditions
}

const signupSelectCommercialCommunicationsEvents: Record<UserType, AnalyticsEvent> = {
  landlord: AnalyticsEvent.LandlordSignupSelectCommercialCommunications,
  tenant: AnalyticsEvent.TenantSignupSelectCommercialCommunications,
  partner: AnalyticsEvent.PartnerSignupSelectCommercialCommunications
}

const signupStartAccountInformationEvents: Record<UserType, AnalyticsEvent> = {
  landlord: AnalyticsEvent.LandlordSignupStartAccountInformation,
  tenant: AnalyticsEvent.TenantSignupStartAccountInformation,
  partner: AnalyticsEvent.PartnerSignupStartAccountInformation
}

const signupErrorAccountExistsEvents: Record<UserType, AnalyticsEvent> = {
  landlord: AnalyticsEvent.LandlordSignupErrorAccountExists,
  tenant: AnalyticsEvent.TenantSignupErrorAccountExists,
  partner: AnalyticsEvent.PartnerSignupErrorAccountExists
}

track(signupStartAccountInformationEvents[userType])

async function linkTenantApplication() {
  try {
    const { propertyId } = await findTenantLead()
    if (propertyId) {
      await createApplication(propertyId)
    }
  } catch {}
}

async function onSignUp() {
  loading.value = true
  errors.value = {}
  try {
    await signUp({ type: userType, email: email.value, password: password.value, termsAndConditions: toc.value })
    if (isLandlord(userType)) {
      track(AnalyticsEvent.LandlordSignupInitiateRegister)
      track(AnalyticsEvent.LandlordSignupRegisterWithEmail)
    } else if (isTenant(userType)) {
      track(AnalyticsEvent.TenantSignupInitiateRegister)
      track(AnalyticsEvent.TenantSignupRegisterWithEmail)
      await linkTenantApplication()
    } else if (isPartner(userType)) {
      track(AnalyticsEvent.PartnerSignupRegisterWithEmail)
    }
    router.push({ name: 'personalInformation' })
  } catch (error) {
    if (error instanceof FormValidationError) {
      errors.value = error.errors
    } else if (error instanceof Error) {
      errors.value = { auth: 'authenticationError.userAlreadyExists' }
      track(signupErrorAccountExistsEvents[userType])
    }
    loading.value = false
  }
}
</script>
