<template>
  <div class="container narrow">
    <div v-if="finished" class="flex column gap-1">
      <h2>{{ t('signin.recoverPassword.success.title') }}</h2>
      <p>{{ t('signin.recoverPassword.success.description') }}</p>
      <RouterLink :to="{ name: 'signIn' }">
        <Button>
          {{ t('common.accept') }}
        </Button>
      </RouterLink>
    </div>
    <form v-else class="flex column gap-1" @submit="onSend">
      <h2>{{ t('signin.recoverPassword.title') }}</h2>
      <p>{{ t('signin.recoverPassword.description') }}</p>
      <InputField
        v-model="email"
        :error="errors['email']"
        :label="t('forms.email')"
        :placeholder="t('forms.emailPlaceholder')"
        type="email"
      />
      <div v-if="loading" class="align-center">
        <Loader />
      </div>
      <template v-else>
        <Button submit>{{ t('signin.recoverPassword.send') }}</Button>
        <RouterLink :to="{ name: 'signIn' }">
          <Button variant="secondary">
            {{ t('common.cancel') }}
          </Button>
        </RouterLink>
      </template>
    </form>
  </div>
</template>
<script lang="ts" setup>
import { Button, InputField, Loader } from '@/components'
import { FormValidationError } from '@/modules/shared/domain/errors/formValidationError'
import { sendPasswordRecoveryEmail } from '@/modules/user/application'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const email = ref<string>('')
const loading = ref(false)
const errors = ref<Record<string, string>>({})
const finished = ref(false)

async function onSend(event: Event) {
  event.preventDefault()
  loading.value = true
  errors.value = {}

  try {
    await sendPasswordRecoveryEmail({ email: email.value })
    finished.value = true
  } catch (error) {
    if (error instanceof FormValidationError) {
      errors.value = error.errors
    }
  }

  loading.value = false
}
</script>
<style lang="sass" scoped>
p
  white-space: pre-line

.button
  width: 100%
</style>
