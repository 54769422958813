import { zodGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/zod/zodGenericSchemaValidator'
import type { SendPasswordRecoveryEmailForm } from '@/modules/user/domain/sendPasswordRecoveryEmailForm'
import { z } from 'zod'

export const sendPasswordRecoveryEmailFormSchema = z.object({
  email: z.string().regex(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/, 'validationError.formatEmail')
})

export function zodSendPasswordRecoveryEmailFormValidatorBuilder() {
  return zodGenericSchemaValidatorBuilder<SendPasswordRecoveryEmailForm>(sendPasswordRecoveryEmailFormSchema)
}
