<template>
  <div class="modal">
    <div class="modal-container">
      <div class="backdrop" @click="onClose"></div>
      <div class="modal-content">
        <Icon v-if="!mandatory" :icon="mdiClose" size="14" @click="onClose" class="close" />
        <div class="content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { mdiClose } from '@mdi/js'
import Icon from '../ui/Icon.vue'

const props = defineProps<{ mandatory?: boolean }>()

const emit = defineEmits(['close'])

function onClose() {
  if (!props.mandatory) {
    emit('close')
  }
}
</script>
<style lang="sass" scoped>
.modal
  width: 100vw
  height: 100vh
  position: fixed
  top: 0
  left: 0
  z-index: 20

.backdrop
  width: 100%
  height: 100%
  background-color: $grey500
  opacity: 0.76
  position: absolute
  top: 0
  left: 0

.modal-container
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0
  z-index: 1
  display: flex
  justify-content: center
  align-items: center

  .modal-content
    z-index: 16
    background-color: white
    border-radius: 2rem
    overflow: hidden
    position: relative
    margin: 1rem

    .close
      color: $grey500
      cursor: pointer
      position: absolute
      right: 2rem
      top: 2rem
      transform: scale(1.5)
      transition: transform 0.1s
      z-index: 1

      &:hover
        transform: scale(1.7)

    .content
      min-width: 300px
      min-height: 200px
      max-width: 40rem
      max-height: 80vh
      overflow-y: auto

      @media only screen and (min-width: 768px)
        min-width: 520px
</style>
