<script lang="ts">
/**
 * @example
 * <Icon :icon="mdiAccountCircleOutline" size="24" />
 *
 * https://pictogrammers.com/library/mdi/
 */
export default {
  name: 'Icon',
  props: {
    icon: {
      type: String,
      required: true
    },
    size: {
      type: [Number, String],
      default: 24
    },
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    customColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<template>
  <svg
    :width="size"
    :height="size"
    :viewBox="viewBox"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    :fill="customColor"
  >
    <path :d="icon" />
  </svg>
</template>
