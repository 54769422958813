<template>
  <Modal v-if="opened" @close="onClose">
    <div class="flex align-center justify-center column gap-1 p-5 primary">
      <img :src="images.congratulations" />
      <h2>{{ t('common.congratulations') }}</h2>
      <p class="pl-2 pr-2">
        {{ t('properties.accountSuccess.main') }}
        <b>{{ t('properties.accountSuccess.bold') }}</b>
      </p>
      <img :src="images.curvedArrow" height="70" />
    </div>
    <div class="flex align-center justify-center column gap-1 p-3">
      <RouterLink :to="{ name: 'resumeOnboarding', params: { id } }" @click="onContinue">
        <Button>{{ t('common.continue') }}</Button>
      </RouterLink>
      <a @click="onClose"> {{ t('properties.completeLater') }} </a>
    </div>
  </Modal>
</template>
<script setup lang="ts">
import { Button, Modal } from '@/components'
import { images } from '@/config/assets/images'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { router } from '@/router'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()

const id = router.currentRoute.value.query['accountSuccess'] as string | undefined
const signupCompleted = id !== undefined
const opened = ref(signupCompleted)

if (signupCompleted) {
  track(AnalyticsEvent.LandlordSignupCompleted)
}

function onContinue() {
  track(AnalyticsEvent.LandlordSignupContinue)
}

function onClose() {
  track(AnalyticsEvent.LandlordSignupContinueLater)
  opened.value = false
}
</script>
