import { zodGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/zod/zodGenericSchemaValidator'
import type { UpdateTenantForm } from '@/modules/tenant/domain/updateTenantForm'
import { z } from 'zod'

export const updateTenantFormSchema = z.object({
  email: z.string().regex(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/, 'validationError.formatEmail'),
  employmentStatus: z.union([z.literal('employed'), z.literal('self-employed'), z.literal('retired')]).nullable(),
  name: z.string().regex(/^.+$/, 'validationError.required'),
  phone: z.string().regex(/^(?:\+34|0034|34)?[6789\*](\d|\*){8}$/, 'validationError.formatPhone'),
  surname: z.string().regex(/^.+$/, 'validationError.required')
})

export function zodUpdateTenantFormValidatorBuilder() {
  return zodGenericSchemaValidatorBuilder<UpdateTenantForm>(updateTenantFormSchema)
}
