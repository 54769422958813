<template>
  <form class="flex column gap-1" @submit="onSubmit">
    <slot></slot>
  </form>
</template>
<script setup lang="ts">
const emit = defineEmits(['submit'])

function onSubmit(event: Event) {
  event.preventDefault()
  emit('submit')
}
</script>
