<template>
  <div class="flex column gap-1" v-if="model">
    <Title path="property.images.title" />
    <div data-test-id="filesSelector" class="card">
      <FileInput
        v-model="model.files"
        name="images"
        type="image"
        :maxFiles="25"
        :instructions="t('property.images.uploadInstructions')"
        :trackSelectEvent="AnalyticsEvent.LandlordPropertyRegisterUploadImages"
        :trackInvalidFormatEvent="AnalyticsEvent.LandlordPropertyRegisterErrorImageFormat"
        @delete="onDelete($event)"
      />
    </div>
    <div data-test-id="photoServiceSelector">
      <Switch
        v-model="model.needsPhotoService"
        :label="t('property.images.professionalPhotoService')"
        :trackSelectEvent="AnalyticsEvent.LandlordPropertyRegisterSelectProfessionalImages"
      />
    </div>
  </div>
  <FormSkeleton v-else />
</template>
<script lang="ts" setup>
import { FileInput, FormSkeleton, Switch, Title } from '@/components'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { deletePropertyImage } from '@/modules/property/application'
import type { PropertyImages } from '@/modules/property/domain/propertyImages'
import type { Document } from '@/modules/shared/domain/document/document'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{ id: string }>()
const model = defineModel<PropertyImages>()

async function onDelete(imageToDelete: Document) {
  if (!model.value) return

  model.value.files = model.value.files.filter((file) => file !== imageToDelete)
  if (imageToDelete.id) {
    await deletePropertyImage(props.id, imageToDelete.id)
  }
}
</script>
