<template>
  <div id="app">
    <FullScreenLoader v-if="loading" />
    <template v-else>
      <DashboardLayout v-if="layoutType === 'dashboard' && user" :user="user">
        <RouterView />
      </DashboardLayout>
      <BasicLayout v-else :user="user">
        <RouterView />
      </BasicLayout>
    </template>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { RouterView, useRoute } from 'vue-router'
import { BasicLayout, FullScreenLoader, DashboardLayout } from './components'
import { findCurrentUser } from './modules/user/application'
import type { User } from './modules/user/domain/user'

const route = useRoute()
const loading = ref(true)
const user = ref<Nullable<User>>(null)
const layoutType = ref<'basic' | 'dashboard'>('basic')

onMounted(async () => {
  try {
    user.value = await findCurrentUser()
  } catch {}
  loading.value = false
})

watch(route, async ({ meta }) => {
  layoutType.value = meta.basicLayout ? 'basic' : 'dashboard'
  if (meta.signedOut) {
    user.value = null
  } else {
    user.value = await findCurrentUser()
  }
})
</script>
<style lang="sass">
@use "@/assets/styles/main.sass" as *

#app
  position: relative
  min-height: 100vh
</style>
