import { setCurrentLandlordBasicPlanVisibility } from '@/modules/landlord/application'
import { createRouter, createWebHistory } from 'vue-router'
import { applicationRoutes } from './applicationRoutes'
import { applicationsRoutes } from './applicationsRoutes'
import { authenticationGuard } from './authenticationGuard'
import { listingsRoutes } from './listingsRoutes'
import { partnerRoutes } from './partnerRoutes'
import { profileRoutes } from './profileRoutes'
import { propertiesRoutes } from './propertiesRoutes'
import { propertyRoutes } from './propertyRoutes'
import { signInRoutes } from './signInRoutes'
import { signUpRoutes } from './signupRoutes'

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    { path: '/', name: 'home', redirect: { name: 'properties' } },
    applicationRoutes,
    applicationsRoutes,
    listingsRoutes,
    partnerRoutes,
    propertiesRoutes,
    propertyRoutes,
    signUpRoutes,
    ...profileRoutes,
    ...signInRoutes,
    { path: '/:pathMatch(.*)*', redirect: '/' }
  ]
})

router.beforeEach(authenticationGuard)

router.beforeEach((to, from, next) => {
  if (to.query.utm === 'basic_plan') {
    setCurrentLandlordBasicPlanVisibility(true)
  }
  next()
})
