<template>
  <div class="container">
    <div class="flex gap-05 align-center mb-3">
      <RouterLink :to="{ name: 'applications' }">
        <IconButton :icon="icons.arrowBack" />
      </RouterLink>
      <h1>{{ t('application.details.title') }}</h1>
    </div>
    <p class="title mb-2">{{ t('application.details.description') }}</p>
    <div v-if="tenants.length">
      <div class="card mb-2" v-for="(tenant, index) in tenants">
        <h2 v-if="index === 0">{{ t('application.details.tenant.mainTenant') }}</h2>
        <h2 v-else>{{ t('application.details.tenant.coTenant') }} {{ index }}</h2>
        <div class="flex wrap gap-1 mt-1">
          <div class="field" :id="field" v-for="field in fields">
            <p class="label">{{ t(`application.details.tenant.${field}`) }}</p>
            <p class="value">{{ fieldFormatter(tenant, field) }}</p>
          </div>
        </div>
        <div class="mt-2" v-if="index === 0">
          <h3>{{ t('application.details.documents.title') }}</h3>
          <div v-for="field in getDocumentationFields(tenant)" class="documents">
            <p class="label">{{ t(`tenant.documents.${field}`) }}</p>
            <div class="flex wrap gap-1">
              <div class="field document" :id="field" v-for="document in tenant.documents[field]">
                <p class="value"><img :src="icons.documentLight" />{{ document.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FormSkeleton v-else />
  </div>
</template>
<script lang="ts" setup>
import { FormSkeleton, IconButton } from '@/components'
import { icons } from '@/config/assets/icons'
import { findAllTenants } from '@/modules/tenant/application'
import type { Tenant } from '@/modules/tenant/domain/tenant'
import { employmentStatusDocumentTypes, TenantDocumentType } from '@/modules/tenant/domain/tenantDocuments'
import { router } from '@/router'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const id = router.currentRoute.value.params.id as string
const tenants = ref<Tenant[]>([])
const fields: (keyof Tenant)[] = ['name', 'surname', 'email', 'phone', 'employmentStatus']

function getDocumentationFields(tenant: Tenant): TenantDocumentType[] {
  return tenant.employmentStatus ? employmentStatusDocumentTypes[tenant.employmentStatus] : []
}

function fieldFormatter(tenant: Tenant, field: keyof Tenant) {
  if (field === 'employmentStatus') {
    return t(`tenant.employmentStatuses.${tenant.employmentStatus}`)
  }
  return tenant[field]?.toString() ?? '-'
}

onMounted(async () => (tenants.value = await findAllTenants(id)))
</script>
<style scoped lang="sass">
.field
  width: 100%

  @media only screen and (min-width: 768px)
    width: calc(50% - 0.5rem)

    &.document
      width: calc(33% - 0.66rem)

    &#email
      width: 100%

.value
  background-color: #F9F9F9
  border-radius: 0.5rem
  padding: 1rem
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.images img
  width: 7.5rem
  height: 7.5rem
  border-radius: 0.5rem
  object-fit: cover

.documents
  margin-top: 1rem

  &:not(:first-of-type)
    padding-top: 1rem
    border-top: 1px solid #E6E6E6

.document .value
  position: relative
  padding-left: 2.5rem

  img
    position: absolute
    left: 0.75rem
    top: 0.8rem
</style>
