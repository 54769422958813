<template>
  <div class="main">
    <div class="container">
      <h1 class="mb-2">{{ t('listings.title') }}</h1>
      <hr />
      <div class="flex gap-1 wrap mt-2">
        <template v-if="listings">
          <ImageCard v-for="(listing, index) in listings" :source="listing.imageUrl">
            <div class="description">
              <div>
                <template v-if="listing.idealistaUrl">
                  <div class="flex justify-between align-center">
                    <p class="title">{{ t('listings.idealistaLink') }}</p>
                    <a
                      class="icon"
                      target="_blank"
                      :href="listing.idealistaUrl"
                      @click="track(AnalyticsEvent.TenantPropertiesSelectIdealista)"
                    >
                      <IconButton :icon="icons.arrowOutward" border small data-test-id="propertyDetails" />
                    </a>
                  </div>
                  <hr />
                </template>
                <p class="title mt-1 mb-05">{{ listing.street }}, {{ listing.city }}</p>
                <p class="light">
                  {{ listing.builtSize }} m² | {{ listing.bedrooms }} {{ t('common.roomsAbbreviation') }}
                </p>
                <p class="mb-1">{{ listing.price }} €/{{ t('common.month') }}</p>
              </div>
              <div v-if="listingApplications[index]?.status === ApplicationStatus.Submitted">
                <hr />
                <div class="flex justify-between align-center">
                  <span>{{ t('listings.applied') }}</span>
                  <img :src="icons.checkCircleFilledGreen" />
                </div>
              </div>
              <RouterLink
                v-else-if="listingApplications[index]?.status === ApplicationStatus.InProgress"
                :to="{ name: 'tenantsInformation', params: { id: listingApplications[index]?.id } }"
              >
                <Button class="mt-1" variant="secondary">{{ t('applications.completeDocumentation') }}</Button>
              </RouterLink>
              <RouterLink
                v-else-if="listingApplications[index]?.status === ApplicationStatus.Filled"
                :to="{ name: 'tenantsInformation', params: { id: listingApplications[index]?.id } }"
              >
                <Button class="mt-1" variant="warning">{{ t('applications.pendingToSend') }}</Button>
              </RouterLink>
              <Button v-else class="mt-1" @click="onRequest(listing)">
                {{ t('listings.request') }}
              </Button>
            </div>
          </ImageCard>
        </template>
        <template v-else>
          <ImageCardSkeleton />
          <ImageCardSkeleton />
          <ImageCardSkeleton />
        </template>
      </div>
    </div>
    <ApplicationInstructions />
  </div>
  <ZapierChatbot />
</template>
<script setup lang="ts">
import { ApplicationInstructions, Button, IconButton, ImageCard, ImageCardSkeleton, ZapierChatbot } from '@/components'
import { icons } from '@/config/assets/icons'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { createApplication, findAllApplications } from '@/modules/application/application'
import { ApplicationStatus, type Application } from '@/modules/application/domain/application'
import { findAllListings } from '@/modules/listing/application'
import type { Listing } from '@/modules/listing/domain/listing'
import { router } from '@/router'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()

const listings = ref<Nullable<Listing[]>>(null)
const listingApplications = ref<Nullable<Application>[]>([])

track(AnalyticsEvent.TenantPropertiesPropertiesList)

onMounted(async () => {
  const applications = await findAllApplications()
  listings.value = await findAllListings()
  listingApplications.value = listings.value.map(
    ({ propertyId }) => applications.find((application) => application.propertyId === propertyId) ?? null
  )
})

async function onRequest(listing: Listing) {
  track(AnalyticsEvent.TenantPropertiesApply)
  const id = await createApplication(listing.propertyId)
  router.push({ name: 'tenantsInformation', params: { id } })
}
</script>
<style scoped lang="sass">
@use "@/assets/styles/responsive.sass" as *

@include lg
  .main
    display: flex
    justify-content: space-between
    align-items: flex-start

    .container
      flex-grow: 1

.description
  display: flex
  flex-direction: column
  justify-content: space-between
  height: 100%

button.button
  padding: 1rem
  width: 100%

hr
  margin: 0.75rem 0rem
</style>
