import { RecoverPassword, ResetPassword, SignIn } from '@/views'
import type { RouteRecordRaw } from 'vue-router'

export const signInRoutes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'signIn',
    component: SignIn,
    meta: { signedOut: true, basicLayout: true }
  },
  {
    path: '/recover',
    name: 'recoverPassword',
    component: RecoverPassword,
    meta: { signedOut: true, basicLayout: true }
  },
  {
    path: '/setNewPassword',
    name: 'resetPassword',
    component: ResetPassword,
    meta: { signedOut: true, basicLayout: true }
  }
]
