<template>
  <Modal mandatory>
    <div class="flex align-center justify-center column gap-1 p-5 primary" data-test-id="tenantPartialSaveModal">
      <img :src="images.congratulations" />
      <h2>{{ t('tenant.partialSave.title') }}</h2>
      <p class="mt-2">{{ t('tenant.partialSave.description') }}</p>
      <p>{{ t('tenant.partialSave.question') }}</p>
    </div>
    <div class="flex align-center justify-center column gap-1 p-3">
      <Button @click="emit('close')">{{ t('common.cancel') }}</Button>
      <Button @click="emit('confirm')" variant="secondary">{{ t('tenant.partialSave.confirm') }}</Button>
    </div>
  </Modal>
</template>
<script setup lang="ts">
import { Button, Modal } from '@/components'
import { images } from '@/config/assets/images'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const emit = defineEmits(['confirm', 'close'])
</script>
<style lang="sass" scoped>
button
  width: 16rem
</style>
