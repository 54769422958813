import { apiEndpointsV1 as v1 } from '@/config/api/endpoints.v1'
import { apiEndpointsV2 as v2 } from '@/config/api/endpoints.v2'
import { i18n } from '@/i18n'
import type { ApiService } from '@/modules/shared/domain/api/apiService'
import type { Document } from '@/modules/shared/domain/document/document'
import { asyncForEach } from '@/utils/array'
import type { Property } from '../domain/property'
import type { PropertyBasicInformation } from '../domain/propertyBasicInformation'
import type { CountryState, PropertyCoreInformation } from '../domain/propertyCoreInformation'
import type { PropertyDocuments } from '../domain/propertyDocuments'
import type { PropertyEquipmentInformation } from '../domain/propertyEquipmentInformation'
import type { PropertyImages } from '../domain/propertyImages'
import type { PricingPlan, PropertyPaymentInformation } from '../domain/propertyPaymentInformation'
import type { PropertyRepository } from '../domain/propertyRepository'
import {
  type PricingPlanDto,
  type PropertyApiDto,
  fromDto,
  fromPricingPlanDto,
  propertyBasicInformationToDto,
  propertyCoreInformationToDto,
  propertyEquipmentInformationToDto,
  toPricingPlanDto
} from './apiDto'
import { findCountryState } from './countryStateGeocode'

export function apiPropertyRepositoryBuilder(apiService: ApiService, apiV2Service: ApiService): PropertyRepository {
  let cache: Nullable<Property> = null
  let cacheId: Nullable<string> = null

  async function uploadDocuments(id: string, type: string, documents: Document[]): Promise<void> {
    const filesToUpload = documents.filter(({ source }) => source !== null).map(({ source }) => source as File)

    for (const file of filesToUpload) {
      await apiV2Service.upload(v2.properties().documents(id).upload(type), file)
    }
  }

  return {
    async add(userId: string) {
      const dto = await apiService.post<PropertyApiDto>(v1.properties().create(), { landlord: userId })
      return fromDto(dto, null, null)
    },
    async deleteDocument(id: string, documentId: string) {
      cache = cacheId = null
      await apiService.delete(v1.properties().documents(id).delete(documentId))
    },
    async deleteImage(id: string, imageId: string) {
      cache = cacheId = null
      await apiV2Service.delete(v2.properties().images(id).delete(imageId))
    },
    async get(id: string) {
      if (id === cacheId && cache !== null) {
        return cache
      }

      const dto = await apiService.get<PropertyApiDto>(v1.properties().find(id))

      let paymentPlan: Nullable<PricingPlan> = null
      try {
        const pricingPlanDto = await apiV2Service.get<PricingPlanDto>(v2.properties().pricingPlan(id).find())
        paymentPlan = fromPricingPlanDto(pricingPlanDto)
      } catch {}

      let state: Nullable<CountryState> = null
      if (dto.address.street) {
        try {
          state = await findCountryState(dto.address.street)
        } catch {}
      }

      cacheId = id
      cache = fromDto(dto, paymentPlan, state)
      return cache
    },
    async getAll(landlordId: string) {
      const dtos = await apiService.get<PropertyApiDto[]>(v1.landlords().properties(landlordId).list())
      return dtos.map((dto) => fromDto(dto, null, null))
    },
    async getCatastralInformation(reference: string) {
      return apiService.get(v1.catastro().find(reference))
    },
    async finishOnboarding(id: string) {
      cache = cacheId = null
      const lang = i18n.global.locale.value
      return apiService.post(v1.properties().finishOnboarding(id, lang))
    },
    async updateBasicInformation(id: string, basicInformation: PropertyBasicInformation): Promise<void> {
      await apiService.patch(v1.properties().update(id), propertyBasicInformationToDto(basicInformation))
      if (cacheId === id && cache !== null) {
        cache.basicInformation = basicInformation
      }
    },
    async updateCoreInformation(id: string, coreInformation: PropertyCoreInformation): Promise<void> {
      cache = cacheId = null
      await apiService.patch(v1.properties().update(id), propertyCoreInformationToDto(coreInformation))
    },
    async updateEquipmentInformation(id: string, equipmentInformation: PropertyEquipmentInformation): Promise<void> {
      await apiService.patch(v1.properties().update(id), propertyEquipmentInformationToDto(equipmentInformation))
      if (cacheId === id && cache !== null) {
        cache.equipmentInformation = equipmentInformation
      }
    },
    async updateImages(id: string, images: PropertyImages): Promise<void> {
      cache = cacheId = null

      await apiService.patch(v1.properties().update(id), {
        management: { needsPhotoService: images.needsPhotoService }
      })

      const filesToUpload = images.files.filter(({ source }) => source !== null).map(({ source }) => source as File)
      await asyncForEach(filesToUpload, async (file) => apiV2Service.upload(v2.properties().images(id).upload(), file))
    },
    async updateDocuments(id: string, documents: PropertyDocuments): Promise<void> {
      cache = cacheId = null

      await uploadDocuments(id, 'energy-cert', documents.energyCertificate)
      await uploadDocuments(id, 'habitability-cert', documents.habitabilityCertificate)
      await uploadDocuments(id, 'property-expense-bill', documents.propertyExpenses)
      await uploadDocuments(id, 'utility-bill', documents.utilityBill)
    },
    async updatePaymentInformation(id: string, paymentInformation: PropertyPaymentInformation): Promise<void> {
      if (paymentInformation.plan === null) return

      const pricingPlanId = toPricingPlanDto(paymentInformation.plan)
      await apiV2Service.put(v2.properties().pricingPlan(id).update(), {
        pricingPlanId
      })

      if (cacheId === id && cache !== null) {
        cache.paymentInformation = paymentInformation
      }
    }
  }
}
