import { useAnalytics } from '@/hooks/useAnalytics'
import { findAllApplications } from '@/modules/application/application'
import { findCurrentUser } from '@/modules/user/application'
import { isLandlord, isPartner, isTenant } from '@/modules/user/domain/user'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export async function authenticationGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const requiresBeingSignedOut = to.matched.some((record) => record.meta.signedOut)
  const requiresBeingLandlord = to.matched.some((record) => record.meta.landlord)
  const requiresBeingTenant = to.matched.some((record) => record.meta.tenant)
  const requiresBeingPartner = to.matched.some((record) => record.meta.partner)

  const { identify } = useAnalytics()

  try {
    const { email, type } = await findCurrentUser()
    identify(email, type)
    if ((requiresBeingSignedOut || requiresBeingTenant || requiresBeingPartner) && isLandlord(type)) {
      return next({ name: 'properties' })
    } else if ((requiresBeingSignedOut || requiresBeingLandlord || requiresBeingPartner) && isTenant(type)) {
      const applications = await findAllApplications()
      return next({ name: applications.length > 0 ? 'applications' : 'listings' })
    } else if ((requiresBeingSignedOut || requiresBeingTenant || requiresBeingLandlord) && isPartner(type)) {
      return next({ name: 'partner' })
    } else {
      return next()
    }
  } catch {
    if (!requiresBeingSignedOut) {
      return next({ name: 'signIn' })
    }
  }
  return next()
}
