import applications from '@/assets/images/applications.png'
import avatar from '@/assets/images/avatar.svg'
import congratulations from '@/assets/images/congratulations.png'
import curvedArrow from '@/assets/images/curved_arrow.png'
import ellipsePink from '@/assets/images/ellipse_pink_300.png'
import googleLogo from '@/assets/images/google_logo.svg'
import partnerProperties from '@/assets/images/partner_properties.png'
import vivaraLogoDark from '@/assets/images/vivara_logo_dark.svg'
import vivaraLogoSmallDark from '@/assets/images/vivara_logo_small_dark.svg'
import vivaraLogoWhite from '@/assets/images/vivara_logo_white.png'
import vivaraSquarePurple from '@/assets/images/vivara_square_purple.svg'

export const images = {
  applications,
  avatar,
  congratulations,
  curvedArrow,
  ellipsePink,
  googleLogo,
  partnerProperties,
  vivaraLogoDark,
  vivaraLogoSmallDark,
  vivaraLogoWhite,
  vivaraSquarePurple
}
