<template>
  <div class="checkbox-wrapper">
    <input type="checkbox" :disabled="disabled" v-model="checked" @change="$emit('update:modelValue', checked)" />
  </div>
</template>

<script setup lang="ts">
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { ref, watch } from 'vue'

const props = defineProps<{
  modelValue: boolean
  disabled?: boolean
  trackSelectEvent?: AnalyticsEvent
}>()

const { track } = useAnalytics()

const checked = ref(props.modelValue)

watch(
  () => props.modelValue,
  (value) => {
    checked.value = value
    if (value && props.trackSelectEvent) {
      track(props.trackSelectEvent)
    }
  }
)
</script>

<style scoped lang="sass">
.checkbox-wrapper
  display: flex
  align-items: center

input[type='checkbox']
  width: 20px
  height: 20px
  appearance: none
  background-color: white
  border: 1px solid $lightBorder
  border-radius: 2px
  cursor: pointer
  position: relative
  display: inline-block

  &:hover
    border-color: #0b0b0b

  &:checked
    background-color: $grey500
    border-color: $grey500

  &:disabled
    background-color: $grey200
    border-color: $grey200

input[type='checkbox']:checked::before
  content: ''
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 12px
  height: 10px
  background-image: url('../../assets/images/checked.svg')
  background-size: contain
  background-repeat: no-repeat
  background-position: center
</style>
