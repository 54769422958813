<template>
  <Teleport to="body" v-if="mobile && opened">
    <div class="backdrop" @click="toggle"></div>
  </Teleport>
  <div class="stepper" :class="{ mobile, opened }">
    <p class="title" @click="toggle">
      <span>
        {{ title }}
      </span>
      <template v-if="mobile">
        <img v-if="opened" :src="icons.chevronDown" />
        <img v-else :src="icons.chevronUp" />
      </template>
    </p>
    <div class="items" v-if="!mobile || opened">
      <slot />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { icons } from '@/config/assets/icons'
import { ref } from 'vue'

const props = defineProps<{ title: string; mobile?: boolean }>()

const opened = ref(false)

function toggle() {
  if (props.mobile) {
    opened.value = !opened.value
  }
}
</script>
<style scoped lang="sass">
@use "@/assets/styles/responsive.sass" as *

.backdrop
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 5
  background: rgba(0, 0, 0, 0.5)

.stepper:not(.mobile)
  position: sticky
  top: 4.25rem
  display: none

  @include lg
    display: block

  .title
    margin-bottom: 1rem

    > span
      font-weight: bold

.stepper.mobile
  display: block
  position: absolute
  top: -2.25rem
  width: 100%
  background-color: white
  z-index: 6
  border-top-left-radius: 1rem
  border-top-right-radius: 1rem
  transition: transform 0.2s

  &.opened
    transform: translateY(calc(-100% + 2.25rem))

  @include lg
    display: none

  .title
    margin: 0
    font-weight: bold
    background-color: #363333
    color: white
    border-top-left-radius: 1rem
    border-top-right-radius: 1rem
    padding: 0.5rem 1.85rem
    display: flex
    justify-content: space-between
    align-items: center

    img
      filter: invert(1)

  .items
    padding: 1rem
</style>
