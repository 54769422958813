import { apiService, apiV2Service } from '@/modules/shared/infrastructure/api'
import { zodSendPasswordRecoveryEmailFormValidatorBuilder } from '@/modules/user/infrastructure/zod/sendPasswordRecoveryEmailFormValidator'
import { zodSignInFormValidatorBuilder } from '@/modules/user/infrastructure/zod/signInFormValidator'
import { zodSignUpFormValidatorBuilder } from '@/modules/user/infrastructure/zod/signUpFormValidator'
import { zodUpdatePasswordFormValidatorBuilder } from '@/modules/user/infrastructure/zod/updatePasswordFormValidator'
import { zodUpdateUserFormValidatorBuilder } from '@/modules/user/infrastructure/zod/updateUserFormValidator'
import { apiUserRepositoryBuilder } from './apiUserRepository'

export const userRepository = apiUserRepositoryBuilder(apiService, apiV2Service)

export const sendPasswordRecoveryEmailFormValidator = zodSendPasswordRecoveryEmailFormValidatorBuilder()
export const signInFormValidator = zodSignInFormValidatorBuilder()
export const signUpFormValidator = zodSignUpFormValidatorBuilder()
export const updatePasswordFormValidator = zodUpdatePasswordFormValidatorBuilder()
export const updateUserFormValidator = zodUpdateUserFormValidatorBuilder()
