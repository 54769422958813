<template>
  <div class="file-input">
    <div class="files" :class="{ empty: modelValue.length === 0 }">
      <div
        class="drag-zone"
        :class="{ active: isDragging }"
        @dragenter="onDragStart"
        @dragover="onDragStart"
        @dragleave="onDragCancel"
        @dragend="onDragCancel"
        @drop="onDragFinished"
        @click="onBrowseFiles"
      >
        <img v-if="type === 'image'" :src="icons.addPhoto" />
        <img v-else :src="icons.addNotes" />
      </div>
      <div class="file" v-for="(document, index) in modelValue" :key="index">
        <template v-if="document.uri">
          <img v-if="isImage(document)" :src="document.uri" :alt="document.name" />
          <a v-else :href="document.uri" target="_blank">{{ document.name }}</a>
        </template>
        <p v-else>{{ document.name }}</p>
        <div class="delete" @click="emit('delete', document)">
          <img :src="icons.delete" />
        </div>
      </div>
    </div>
    <p v-for="error in errors" class="error-message center">{{ t(`forms.${error}`) }}</p>
    <div class="instructions">
      <p>
        <span>{{ type === 'image' ? t('forms.fileInputImage') : t('forms.fileInputFile') }}&nbsp;</span>
        <input
          type="file"
          name="files[]"
          :id="`${name}Input`"
          :accept="mimeTypes[type]"
          multiple
          @change="onInputChange"
        />
        <label :for="`${name}Input`">
          <strong>{{ t('forms.fileInputBrowse') }}</strong>
        </label>
      </p>
      <p>
        {{ maxFiles }} {{ t('forms.fileInputMax') }}.
        {{ t('forms.fileInputMaxSize', [formatInMegabytes(maxSizeInBytes)]) }}.
        <span v-if="instructions">{{ instructions }}</span>
      </p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { icons } from '@/config/assets/icons'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import type { Document } from '@/modules/shared/domain/document/document'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  modelValue: Document[]
  name: string
  type: keyof typeof mimeTypes
  maxFiles: number
  instructions?: string
  trackSelectEvent?: AnalyticsEvent
  trackInvalidFormatEvent?: AnalyticsEvent
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: Document[]): void
  (e: 'delete', value: Document): void
}>()

const { t } = useI18n()
const { track } = useAnalytics()

const maxSizeInBytes = 20 * 1024 * 1024
const mimeTypes = {
  image: 'image/png,image/jpg,image/jpeg',
  file: 'image/png,image/jpg,image/jpeg,application/pdf'
}

const isDragging = ref(false)
const errors = ref<Set<string>>(new Set())

function addFiles(fileList: FileList) {
  const documentsToAdd: Document[] = []
  errors.value = new Set()

  for (const file of fileList) {
    if (!mimeTypes[props.type].includes(file.type)) {
      errors.value.add('fileInputInvalidFormat')
      if (props.trackInvalidFormatEvent) {
        track(props.trackInvalidFormatEvent)
      }
    } else if (file.size >= maxSizeInBytes) {
      errors.value.add('fileInputInvalidSize')
    } else if (props.modelValue.length + documentsToAdd.length > props.maxFiles) {
      errors.value.add('fileInputInvalidFileCount')
    } else {
      documentsToAdd.push({
        id: null,
        name: file.name,
        source: file,
        uri: mimeTypes.image.includes(file.type) ? URL.createObjectURL(file) : null
      })
    }
  }

  if (documentsToAdd.length > 0) {
    emit('update:modelValue', [...props.modelValue, ...documentsToAdd])
    if (props.trackSelectEvent) {
      track(props.trackSelectEvent)
    }
  }
}

function isImage({ name }: Document): boolean {
  return ['.png', '.jpg', '.jpeg'].some((extension) => name.toLowerCase().endsWith(extension))
}

function formatInMegabytes(bytes: number) {
  return (bytes / 1024 / 1024).toFixed(0)
}

function onDragStart(event: DragEvent) {
  isDragging.value = true
  event.preventDefault()
  event.stopPropagation()
}

function onDragCancel(event: DragEvent) {
  isDragging.value = false
  event.preventDefault()
  event.stopPropagation()
}

function onDragFinished(event: DragEvent) {
  isDragging.value = false
  event.preventDefault()
  event.stopPropagation()

  if (event.dataTransfer) {
    addFiles(event.dataTransfer.files)
  }
}

function onInputChange(event: Event) {
  const input = event.target as HTMLInputElement
  if (input.files) {
    addFiles(input.files)
  }
  input.value = ''
}

function onBrowseFiles() {
  document.getElementById(`${props.name}Input`)?.click()
}
</script>
<style lang="sass" scoped>
$itemSize: 7.2rem
$itemRadius: 1rem

.file-input
  .instructions
    text-align: center

    p
      font-size: 0.875rem
      line-height: 1rem

  input
    display: none

  label:hover
    cursor: pointer
    text-decoration: underline

  .files
    display: flex
    flex-wrap: wrap
    gap: 0.5rem
    margin-bottom: 1.5rem

    .drag-zone
      height: $itemSize
      width: $itemSize
      background-color: #f0f0f0
      border-radius: $itemRadius
      border: 2px dashed #d4d4d4
      display: flex
      align-items: center
      justify-content: center
      box-sizing: border-box
      cursor: pointer

      &.active
        border-color: #0b0b0b

      img
        transition: transform 0.2s

      &:not(.active):hover
        img
          transform: scale(1.1)

    .file
      border-radius: $itemRadius
      height: $itemSize
      width: $itemSize
      overflow: hidden
      box-sizing: border-box
      border: 1px solid #d4d4d4
      display: flex
      justify-content: center
      align-items: center
      position: relative
      background-color: #f0f0f0

      p
        padding: 0.5rem
        font-size: 0.75rem
        color: #9F9F9F
        text-align: center
        word-break: break-all

      img
        max-width: 100%
        max-height: 100%

      .delete
        width: 2rem
        height: 2rem
        display: flex
        justify-content: center
        align-items: center
        position: absolute
        top: 0.5rem
        right: 0.5rem
        border-radius: 1rem
        background-color: white
        cursor: pointer
        opacity: 0.75

        &:hover
          opacity: 1

    &.empty
      .drag-zone
        width: 100%
        flex-grow: 1
</style>
