<template>
  <div class="input-wrapper">
    <div class="input" :class="{ error: error && error !== '' }">
      <label :for="inputId">{{ label }}</label>
      <input
        type="number"
        class="input-field"
        :placeholder="placeholder"
        :id="inputId"
        :value="modelValue"
        :disabled="disabled"
        onkeydown="return event.keyCode !== 190 && event.keyCode !== 188"
        @input="onInput"
        @blur="onBlur"
      />
    </div>
    <div class="error-message" v-if="error">{{ t(error) }}</div>
  </div>
</template>
<script setup lang="ts">
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  modelValue: Nullable<number>
  label?: string
  error?: string
  placeholder?: string
  disabled?: boolean
  trackEnterEvent?: AnalyticsEvent
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: Nullable<number>): void
}>()

const { t } = useI18n()
const { track } = useAnalytics()

const inputId = Math.random().toString(36).slice(2, 9)

function onInput(event: Event) {
  const target = event.target as HTMLInputElement
  emit('update:modelValue', target.value !== '' ? Math.abs(parseInt(target.value)) : null)
}

function onBlur() {
  if (props.modelValue && props.trackEnterEvent) {
    track(props.trackEnterEvent)
  }
}
</script>
<style scoped lang="sass">
.input-wrapper
  flex-grow: 1

.input
  position: relative
  display: flex
  flex-direction: column
  width: 100%

  label
    margin-bottom: 0.5rem
    font-weight: 400
    font-size: 0.875rem

  &.error
    .input-field
      border: 1px solid red

  .input-field
    box-sizing: border-box
    height: 2.75rem
    padding: 0.75rem 0.5rem
    border: 1px solid $lightBorder
    border-radius: 6px
    font-size: 1rem

    &::placeholder
      color: $grey300
      font-weight: 300
      font-size: 0.875rem

    &:disabled
      background-color: $grey200
</style>
