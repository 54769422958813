<template>
  <Modal mandatory>
    <div class="flex align-center justify-center column gap-1 p-5 primary" data-test-id="applicationCompletedModal">
      <img :src="images.congratulations" />
      <h2>{{ t('common.congratulations') }}</h2>
      <p class="pl-2 pr-2">
        <b>{{ t('application.documentationCompleted.bold') }}</b>
        {{ t('application.documentationCompleted.main') }}
      </p>
      <img :src="images.curvedArrow" height="70" />
    </div>
    <div class="flex align-center justify-center column gap-1 p-3">
      <RouterLink
        :to="{ name: 'applications' }"
        @click="track(AnalyticsEvent.TenantApplicationCompletedApplicationsList)"
      >
        <Button>{{ t('application.documentationCompleted.finish') }}</Button>
      </RouterLink>
    </div>
  </Modal>
</template>
<script setup lang="ts">
import { Button, Modal } from '@/components'
import { images } from '@/config/assets/images'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()
</script>
