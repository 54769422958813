<template>
  <div class="instructions">
    <img :src="images.vivaraSquarePurple" />
    <p class="title mt-1">{{ t('listings.instructions.step1.title') }}</p>
    <p class="mt-05 mb-05">{{ t('listings.instructions.step1.description') }}</p>
    <p class="title mt-1">{{ t('listings.instructions.step2.title') }}</p>
    <p class="mt-05 mb-05">{{ t('listings.instructions.step2.description') }}</p>
  </div>
</template>
<script setup lang="ts">
import { images } from '@/config/assets/images'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
<style lang="sass" scoped>
@use "@/assets/styles/responsive.sass" as *

.instructions
  box-sizing: border-box
  background-color: #F8E2FC
  border-radius: 1.5rem
  padding: 1.5rem
  margin: 1.5rem
  margin-top: 0rem
  width: calc(100% - 3rem)
  border: 1px solid #F1C5F9

  @include md
    margin: 2rem
    margin-top: 0rem
    width: 36.875rem

  @include lg
    margin-top: 2rem
    width: 20rem
    flex-shrink: 0

  p
    white-space: pre-line
</style>
