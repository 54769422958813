import { zodGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/zod/zodGenericSchemaValidator'
import type { SignUpForm } from '@/modules/user/domain/signUpForm'
import { z } from 'zod'

export const signUpFormSchema = z.object({
  email: z.string().regex(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/, 'validationError.formatEmail'),
  password: z.string().regex(/^.{6,20}$/, 'validationError.formatPassword'),
  termsAndConditions: z.literal(true, { errorMap: () => ({ message: 'validationError.requiredTermsAndConditions' }) }),
  type: z.union([z.literal('landlord'), z.literal('partner'), z.literal('tenant')])
})

export function zodSignUpFormValidatorBuilder() {
  return zodGenericSchemaValidatorBuilder<SignUpForm>(signUpFormSchema)
}
