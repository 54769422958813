<template>
  <div class="free" v-if="free">{{ t('property.payment.free') }}</div>
  <div class="plan" :class="{ dramatic: free, selected }" :data-test-id="`${plan}PlanSelector`">
    <div class="main">
      <div class="title">
        <div class="check" @click="emit('select')"></div>
        <div>
          <h3>{{ t(`property.payment.${plan}.name`) }}</h3>
          <p class="mt-05">{{ t(`property.payment.${plan}.description`) }}</p>
        </div>
      </div>
      <div class="price">{{ t(`property.payment.${plan}.price`) }}</div>
    </div>
    <div class="details" v-if="opened">
      <template v-for="feature in allPricingPlanFeatures">
        <div v-if="isFeatureIncludedInPricingPlan(feature, plan)">
          <img :src="icons.checkGreen" />
          <p>{{ t(`property.payment.features.${feature}`) }}</p>
        </div>
      </template>
    </div>
    <div class="more-details" @click="emit('toggle')">
      <span v-if="opened">{{ t('property.payment.lessDetails') }}</span>
      <span v-else>{{ t('property.payment.moreDetails') }}</span>
      <img v-if="opened" :src="icons.chevronUp" />
      <img v-else :src="icons.chevronDown" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { icons } from '@/config/assets/icons'
import {
  isFeatureIncludedInPricingPlan,
  PricingPlan,
  PricingPlanFeature
} from '@/modules/property/domain/propertyPaymentInformation'
import { useI18n } from 'vue-i18n'

defineProps<{
  plan: PricingPlan
  opened: boolean
  selected?: boolean
  free?: boolean
}>()

const emit = defineEmits(['select', 'toggle'])

const { t } = useI18n()

const allPricingPlanFeatures = Object.values(PricingPlanFeature)
</script>
<style lang="sass" scoped>
@keyframes appear
  from
    opacity: 0
    transform: scale(0.95)
  to
    opacity: 1
    transform: scale(1)

.free
  box-sizing: border-box
  background-color: #0B0B0B
  border-top-right-radius: 1.25rem
  border-top-left-radius: 1.25rem
  text-align: right
  padding: 1rem
  padding-bottom: 2rem
  position: relative
  z-index: 0
  top: 1rem
  margin-top: 1rem
  left: 0
  width: 100%
  color: white

.plan
  position: relative
  z-index: 1
  padding: 2rem 1.25rem
  border-radius: 1.25rem
  border: 1px solid #F0F0F0
  background-color: white
  margin-top: 2rem

  .main
    display: flex
    align-items: center
    gap: 1rem

    .price
      width: 14rem

    @media only screen and (max-width: 768px)
      flex-direction: column
      align-items: flex-start

  .title
    display: flex
    gap: 1rem
    width: 100%
    padding-right: 1rem

    @media only screen and (min-width: 768px)
      border-right: 1px solid #F0F0F0

    p
      font-size: 1rem

    .check
      flex-shrink: 0
      width: 1rem
      height: 1rem
      border-radius: 50%
      border: 2px solid #0B0B0B
      margin-top: 0.25rem
      cursor: pointer
      transition: transform 0.15s

      &:hover
        transform: scale(1.1)

  .details
    margin-top: 2rem
    animation: appear 0.25s ease

    > div
      display: flex
      gap: 1rem
      align-items: center

      p
        font-size: 1rem

    @media only screen and (min-width: 768px)
      margin-left: 2.25rem

  .more-details
    margin-top: 2rem
    cursor: pointer

    @media only screen and (min-width: 768px)
      margin-left: 2.25rem

    span
      margin-right: 0.5rem

    img
      transform: translateY(-1px)

    &:hover
      text-decoration: underline

  &.selected
    background-color: #F6F3A0
    border: 1px solid #0B0B0B

    .check::after
      content: ''
      width: 0.6rem
      height: 0.6rem
      border-radius: 50%
      background-color: #0B0B0B
      display: block
      position: relative
      top: 0.2rem
      left: 0.2rem

    .title
      border-color: #0B0B0B

  &.dramatic
    margin-top: 0
    border-color: transparent
    box-shadow: 0px 4px 4px -1px #0B0B0B1A

    &.selected
      border: 1px solid #0B0B0B
</style>
